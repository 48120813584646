export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
export const VERIFY_RESET_TOKEN_SUCCESS = "VERIFY_RESET_TOKEN_SUCCESS";
export const VERIFY_RESET_TOKEN_FAIL = "VERIFY_RESET_TOKEN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";
export const AUTH_USER = "AUTH_USER";
export const USER_PROFILE="USER_PROFILE";
export const UPLOAD_USER_PROFILE="UPLOAD_USER_PROFILE";
export const UPDATE_USER_PASSWORD="UPDATE_USER_PASSWORD";
export const UPDATE_USER_DETAILS="UPDATE_USER_DETAILS";
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const GET_SKILLS_SUCCESS = "GET_SKILLS_SUCCESS";
export const GET_SKILLS_FAIL = "GET_SKILLS_FAIL";
export const GET_ALL_SKILLS_SUCCESS = "GET_ALL_SKILLS_SUCCESS";
export const GET_ALL_SKILLS_FAIL = "GET_ALL_SKILLS_FAIL";
export const DELETE_EVALUATE_SKILL = "DELETE_EVALUATE_SKILL";

export const CLEAR_PARSED_JD = "CLEAR_PARSED_JD";
export const CLEAR_CREATED_JD = "CLEAR_CREATED_JD";

export const GET_SELF_EVAL_SKILLS_SUCCESS = "GET_SELF_EVAL_SKILLS_SUCCESS";
export const GET_SELF_EVAL_SKILLS_FAIL = "GET_SELF_EVAL_SKILLS_FAIL";
export const GET_SELF_EVAL_TEST_SUCCESS = "GET_SELF_EVAL_TEST_SUCCESS";
export const GET_SELF_EVAL_TEST_FAIL = "GET_SELF_EVAL_TEST_FAIL";
// 
export const SUBMIT_SELF_EVALUATION_SUCCESS = "SUBMIT_SELF_EVALUATION_SUCCESS";
export const SUBMIT_SELF_EVALUATION_FAIL = "SUBMIT_SELF_EVALUATION_FAIL";

export const SUBMIT_MCQ_TEST_SUCCESS = "SUBMIT_MCQ_TEST_SUCCESS";
export const SUBMIT_MCQ_TEST_FAIL = "SUBMIT_MCQ_TEST_FAIL";
export const GET_JOBSLIST_SUCCESS = "GET_JOBSLIST_SUCCESS";

export const SUBMIT_AI_INTERVIEW_ANSWER_SUCCESS = "SUBMIT_AI_INTERVIEW_ANSWER_SUCCESS";
export const SUBMIT_AI_INTERVIEW_ANSWER_FAIL = "SUBMIT_AI_INTERVIEW_ANSWER_FAIL";

export const UPLAOD_AI_CV_SUCCESS = "UPLAOD_AI_CV_SUCCESS";
export const UPLAOD_AI_CV_FAIL = "UPLAOD_AI_CV_FAIL";

export const CLEAR_DATA_REDUCER = "CLEAR_DATA_REDUCER";
export const SUBMIT_CODE_TEST_SUCCESS = "SUBMIT_CODE_TEST_SUCCESS";
export const GET_JOBS_SUCCESS = "GET_JOBS_SUCCESS";
export const GET_SKILL_SUCCESS = "GET_SKILL_SUCCESS";
export const GET_ALL_JOBS_SUCCESS = "GET_ALL_JOBS_SUCCESS";
export const GET_HR_DASH_DATA_SUCCESS = "GET_HR_DASH_DATA_SUCCESS";
export const GET_BATCHES_SUCCESS = "GET_BATCHES_SUCCESS";
export const DELETE_BATCHES_SUCCESS = "DELETE_BATCHES_SUCCESS";
export const GET_JOB_SUCCESS = "GET_JOB_SUCCESS";
export const APPLY_JOB_SUCCESS = "APPLY_JOB_SUCCESS";
export const GET_ALL_LIST_JOB_SUCCESS = "GET_ALL_LIST_JOB_SUCCESS";
export const UPDATE_JOB_SUCCESS = "UPDATE_JOB_SUCCESS";
export const UPDATE_JOB_SKILLS_SUCCESS = "UPDATE_JOB_SKILLS_SUCCESS";
export const DELETE_JOB_SUCCESS = "DELETE_JOB_SUCCESS";
export const CREATE_JOB_SUCCESS = "CREATE_JOB_SUCCESS";
export const UPDATE_JOB_STATUS_SUCCESS = "UPDATE_JOB_STATUS_SUCCESS";
export const UPLOAD_CVS_JOB_SUCCESS = "UPLOAD_CVS_JOB_SUCCESS";
export const CLEAR_ADD_JOB_DATA = "CLEAR_ADD_JOB_DATA";
export const GET_CANDIDATES_SUCCESS = "GET_CANDIDATES_SUCCESS";
export const GET_CANDIDATES_JD_SUCCESS = "GET_CANDIDATES_JD_SUCCESS";
export const GET_APPLICANT_SUCCESS = "GET_APPLICANT_SUCCESS";
export const PARSE_JD_SUCCESS = "PARSE_JD_SUCCESS";
export const GET_APPLICANT_RESUME_SUCCESS = "GET_APPLICANT_RESUME_SUCCESS";
export const TOGGLE_THEME = "TOGGLE_THEME";
export const ADD_HR_REMARKS_SUCCESS = "ADD_HR_REMARKS_SUCCESS";
export const GET_APPLICANT_REPORT_SUCCESS = "GET_APPLICANT_REPORT_SUCCESS";
export const DELETE_APPLICANT_SUCCESS = "DELETE_APPLICANT_SUCCESS";
export const VIEW_APPLICANT_REPORT_SUCCESS = "VIEW_APPLICANT_REPORT_SUCCESS";

export const LOGIN_CANDIDATE_SUCCESS = "LOGIN_CANDIDATE_SUCCESS";
export const GET_CANDIDATE_DASHBOARD_SUCCESS = "GET_CANDIDATE_DASHBOARD_SUCCESS";
export const GET_CANDIDATES_BY_BATCHID_SUCCESS =
  "GET_CANDIDATES_BY_BATCHID_SUCCESS";
  export const GET_QA_BY_THREADID_SUCCESS =
    "GET_QA_BY_THREADID_SUCCESS";
export const START_INTERVIEW_SUCCESS = "START_INTERVIEW_SUCCESS";
export const SUBMIT_INTERVIEW_QUESTION_SUCCESS = "SUBMIT_INTERVIEW_QUESTION_SUCCESS";
export const END_INTERVIEW_SUCCESS = "END_INTERVIEW_SUCCESS";
export const START_INTERVIEW_EMP_SUCCESS = "START_INTERVIEW_EMP_SUCCESS";
export const SUBMIT_INTERVIEW_QUESTION_EMP_SUCCESS =
  "SUBMIT_INTERVIEW_QUESTION_EMP_SUCCESS";
export const END_INTERVIEW_EMP_SUCCESS = "END_INTERVIEW_EMP_SUCCESS";
export const SEND_INTERVIEW_INVITE_SUCCESS = "SEND_INTERVIEW_INVITE_SUCCESS";

export const GET_INTERVIEWS_SUCCESS = "GET_INTERVIEWS_SUCCESS";
export const GET_INTERVIEW_STATUS_SUCCESS = "GET_INTERVIEW_STATUS_SUCCESS";
export const GET_EMPLOYEES_SUCCESS = "GET_EMPLOYEES_SUCCESS";

export const GET_BATCH_SUCCESS = "GET_BATCH_SUCCESS";
export const GET_JD_BATCHES_SUCCESS = "GET_JD_BATCHES_SUCCESS";
export const UPDATE_BATCH_TITLE_SUCCESS = "UPDATE_BATCH_TITLE_SUCCESS";
export const UPDATE_CANDIDATE_SUCCESS = "UPDATE_CANDIDATE_SUCCESS";
export const CLEAR_CANDIDATES = "CLEAR_CANDIDATES";

export const GET_ALL_LIST_COURSES = "GET_ALL_LIST_COURSES";
export const GET_APPLICANT_VIDEO_SUCCESS = "GET_APPLICANT_VIDEO_SUCCESS";
export const PLAY_APPLICANT_VIDEO_SUCCESS = "PLAY_APPLICANT_VIDEO_SUCCESS";
export const CREATE_AUDIO_VIDEO = "CREATE_AUDIO_VIDEO";
export const GET_AI_SCORE_REPORT = "GET_AI_SCORE_REPORT";
export const GET_COURSE_SUGGESTIONS = "GET_COURSE_SUGGESTIONS";
export const CLEAR_BATCH_DATA = "CLEAR_BATCH_DATA";
export const GET_SOCKET_PROGRESS = "GET_SOCKET_PROGRESS";

export const GET_EMPLOYEE_PROFILE_DETAIL = "GET_EMPLOYEE_PROFILE_DETAIL";
export const UPDATE_EMPLOYEE_PROFILE_DETAIL = "UPDATE_EMPLOYEE_PROFILE_DETAIL";

export const GET_BATCH_PROGRESS_SUCCESS = "GET_BATCH_PROGRESS_SUCCESS";
export const GET_MODEL_NAME_WITH_JOBID_SUCCESS = "GET_MODEL_NAME_WITH_JOBID_SUCCESS";
