import React, { useState, useEffect } from 'react';
import Plot from 'react-plotly.js';
import { getAIInterviewScore } from '../../services/dashboard';
import { useParams, Link } from 'react-router-dom';
import { Button, Card, CardContent, Typography } from '@mui/material';
import html2pdf from 'html2pdf.js';

const AiScoreReport = ({
    // response 
}) => {
    const { apct_id } = useParams();
    const [response, setResponse] = useState({});
    const [loading, setLoading] = useState(true); // State variable for loading

    const fetchAIScoreData = async () => {
        for (let i = 0; i < 10; i++) {
            let interviewData = await getAIInterviewScore(apct_id);
            if (interviewData.status === 200) {
                setResponse(interviewData.data.response);
                setLoading(false); // Set loading to false when data is fetched
                break;
            }
        }
    }

    const handleDownload = () => {
        const element = document.getElementById('report');
        const options = {
            filename: `ai-score.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { dpi: 192, letterRendering: true },
            jsPDF: { unit: 'in', format: 'A3', orientation: 'portrait', margin: { top: 1, right: 0.5, bottom: 1, left: 0.5 } }
        };

        html2pdf().from(element).set(options).save();
    }

    useEffect(() => {
        fetchAIScoreData();
    }, []);

    const { interviewerName, positionApplied, jobCode, feedback, questionList, answerList } = response;
    const config = {
        displayModeBar: 'hover',
        displaylogo: false, // Remove Plotly logo
        responsive: true,
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    // Adjust question numbers if the first one is 0
    const adjustedQuestionList = (question_id) => {
        console.log("QQQ===>",question_id);
        return question_id==0?question_id+1:question_id;
    }

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Button onClick={handleDownload}>Download as PDF</Button>
                <h1 style={{ textAlign: 'center', flex: 1 }}>AI Score Report</h1>
                <Link to="/hr/candidates" style={{ marginRight: '20px' }}>Back</Link>
            </div>
            {loading ? (
                <p>Loading...</p> // Loader to display while fetching data
            ) : (
                <div style={{ textAlign: 'center' }} id="report">
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
                        <p><strong>Interviewee:</strong> {interviewerName}</p>
                        <p><strong>Position Applied:</strong> {positionApplied}</p>
                        <p><strong>Job Code:</strong> {jobCode}</p>
                    </div>

                    {feedback && feedback.map((item, index) => {
                        const { question_id, score, emotion_score, brief_explanation } = item;
                        const scores = Object.keys(score).map(key => score[key]);
                        const labels = Object.keys(score).map(label => capitalizeFirstLetter(label));

                        const emotionsScores = emotion_score
                            ? Object.keys(emotion_score).map(key => emotion_score[key])
                            : [];

                        const emotionsLabels = emotion_score
                            ? Object.keys(emotion_score).map(label => capitalizeFirstLetter(label))
                            : [];

                        return (
                            <Card key={question_id} style={{ marginBottom: '40px' }}>
                                <CardContent>
                                    <Typography variant="h5" component="h2">Question {feedback[0]?.question_id ===0?+question_id+1:question_id}</Typography>
                                    <Typography variant="body1">{questionList[index]}</Typography>
                                    <Typography variant="body2"><strong>Answer:</strong> {answerList[index]}</Typography>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Plot
                                            data={[
                                                {
                                                    type: 'pie',
                                                    values: scores,
                                                    labels: labels,
                                                    textinfo: 'label+percent',
                                                    insidetextorientation: 'radial'
                                                }
                                            ]}
                                            layout={{ title: `Score Breakdown for Question ${feedback[0]?.question_id ===0?+question_id+1:question_id}` }}
                                            config={config}
                                            style={{ flex: 1 }}
                                        />
                                        <table style={{ flex: 1, marginLeft: '20px', borderCollapse: 'collapse', width: '50%' }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ border: '1px solid black', padding: '8px' }}>Criteria</th>
                                                    <th style={{ border: '1px solid black', padding: '8px' }}>Score (out of 10)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {labels.map((label, i) => (
                                                    <tr key={i}>
                                                        <td style={{ border: '1px solid black', padding: '8px' }}>{label}</td>
                                                        <td style={{ border: '1px solid black', padding: '8px' }}>{scores[i]}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <Typography variant="body2"><strong>Explanation:</strong> {brief_explanation}</Typography>
                                    {emotionsScores && emotionsScores.length ? (
                                        <>
                                            <Typography variant="h6" component="h3">Emotions Analysis</Typography>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <Plot
                                                    data={[
                                                        {
                                                            type: 'bar',
                                                            x: emotionsLabels, // Labels go on the x-axis
                                                            y: emotionsScores, // Scores go on the y-axis
                                                            text: emotionsScores.map(String), // Add text labels for each bar
                                                            textposition: 'auto',
                                                            hoverinfo: 'label+percent',
                                                            marker: {
                                                                color: 'rgba(55,128,191,0.6)',
                                                                line: {
                                                                    color: 'rgba(55,128,191,1.0)',
                                                                    width: 2
                                                                }
                                                            }
                                                        }
                                                    ]}
                                                    layout={{
                                                        title: `Emotion Breakdown for Question ${feedback[0]?.question_id ===0?+question_id+1:question_id}`,
                                                        xaxis: { title: 'Emotions' },
                                                        yaxis: { title: 'Scores (out of 10)' }
                                                    }}
                                                    config={config}
                                                    style={{ flex: 1 }}
                                                />
                                                <table style={{ flex: 1, marginLeft: '20px', borderCollapse: 'collapse', width: '50%' }}>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ border: '1px solid black', padding: '8px' }}>Criteria</th>
                                                            <th style={{ border: '1px solid black', padding: '8px' }}>Score (out of 10)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {emotionsLabels.map((label, i) => (
                                                            <tr key={i}>
                                                                <td style={{ border: '1px solid black', padding: '8px' }}>{label}</td>
                                                                <td style={{ border: '1px solid black', padding: '8px' }}>{emotionsScores[i]}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>
                                    ) : null}
                                </CardContent>
                            </Card>
                        );
                    })}
                </div>
            )}
        </div>
    );
}

export default AiScoreReport;
