import { useState, useEffect, useRef } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import getLPTheme from "./styles/getLPTheme";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Box from "@mui/material/Box";
import Header from "./layouts/Header";
import SideBar from "./layouts/SideBar";
import { connect } from "react-redux";
import Spinner from "react-spinkit";
import { authUser } from "./redux/actions/auth";
import { toggleThemeAction } from "./redux/actions/dashboard";
import { setAuthToken } from "./utils/setAuthToken";
import { Helmet, HelmetProvider } from "react-helmet-async";
import PrivateRoute from "./routes/PrivateRoutes";
import PublicRoute from "./routes/PublicRoutes";
import CandidateRoutes from "./routes/CandidateRoutes";
import { IconButton } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const App = ({
  auth: { isLoggedIn, user },
  isLoading,
  messages,
  isCandLoggedIn,
  toggleThemeAction,
}) => {
  setAuthToken(localStorage.getItem("token"));

  const [open, setOpen] = useState(true);
  const [mode, setMode] = useState("light");
  const LPtheme = createTheme(getLPTheme(mode));
  const [mainLayout, setMainLayout] = useState("");

  const ref = useRef();

  const [pos, setPos] = useState(false);

  const handleScroll = () => {
    let scrollPosition = window.pageYOffset;
    if (scrollPosition > 300) {
      setPos(true);
    } else {
      setPos(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);

    // Remove the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, []);

  useEffect(() => {
    if (messages && messages.length > 0) {
      messages.map((msg) => {
        toast(msg.msg);
      });
    }
  }, [messages]);

  const toggleColorMode = () => {
    setMode((prev) => (prev === "dark" ? "light" : "dark"));
  };

  useEffect(() => {
    toggleThemeAction(mode);
    document.body.className = `theme-${mode}`;
  }, [mode]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ToastContainer />
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(16, 16, 16, 0.5)",
            zIndex: 999990,
          }}
        >
          <Spinner
            name="three-bounce"
            style={{
              marginTop: "50vh",
              marginLeft: "50vw",
              zIndex: 999999,
              color: "#0959AA",
            }}
          />
        </div>
      ) : (
        ""
      )}

      <ThemeProvider theme={LPtheme}>
        <CssBaseline />

        <Box sx={{ display: "flex" }} className={"theme-" + mode} ref={ref}>
          <Router>
            {isLoggedIn ? (
              <>
                <Header
                  mode={mode}
                  toggleColorMode={toggleColorMode}
                  open={open}
                  handleDrawerOpen={handleDrawerOpen}
                  role={user && user.u_role}
                />
                <HelmetProvider>
                  <Helmet>
                    <title>L&D : {mainLayout && mainLayout.label}</title>
                  </Helmet>
                </HelmetProvider>
              </>
            ) : (
              ""
            )}
            {isCandLoggedIn && isCandLoggedIn && <CandidateRoutes />}
            {isLoggedIn && isLoggedIn ? (
              <>
                <SideBar
                  open={open}
                  handleDrawerClose={handleDrawerClose}
                  setMainLayout={setMainLayout}
                  mainLayout={mainLayout}
                  role={user && user.u_role}
                />
                <PrivateRoute
                  open={open}
                  mode={mode}
                  role={user && user.u_role}
                  breadCrum={mainLayout && mainLayout.breadCrum}
                />
              </>
            ) : (
              <PublicRoute />
            )}
          </Router>
        </Box>
      </ThemeProvider>
      {pos && (
        <IconButton
          sx={{
            position: "fixed",
            bottom: "90px",
            right: "20px",
            zIndex: 9999,
            backgroundColor: "#89CFF0",
          }}
        >
          <KeyboardArrowUpIcon
            color="primary"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            fontSize="large"
          />
        </IconButton>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  isLoading: state.loader.isLoading,
  messages: state.message,
  isCandLoggedIn: state.candidate.isCandLoggedIn,
});

const mapDispatchToProps = { authUser, toggleThemeAction };

export default connect(mapStateToProps, mapDispatchToProps)(App);
