import { useEffect, useState } from "react";
import { Typography, Box, Button } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import AIInterview from "./AIInterview";
import { getInterviewStatus } from "../../redux/actions/candidate";

const CandidateDashboard = ({
  getInterviewStatus,
  auth: { user },
  interviewStatus,
}) => {
  useEffect(() => {
    if (user && user.u_apct_id_fk) {
      //u_apct_id_fk
      getInterviewStatus(user.u_apct_id_fk);
    }
  }, [user]);

  return (
    <>
      {interviewStatus && interviewStatus.apct_stage === "completed" ? (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="subtitle2" textAlign="center" color="primary">
            Your AI interview is completed, HR will get back to you.
          </Typography>
        </Box>
      ) : (
        <AIInterview userId={user && user.u_id} />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  interviewStatus: state.candidate.interviewStatus,
});

const mapDispatchToProps = { getInterviewStatus };

export default connect(mapStateToProps, mapDispatchToProps)(CandidateDashboard);
