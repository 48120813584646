import { useState, useEffect } from "react";
import {
  Stack,
  Card,
  CardContent,
  Typography,
  Divider,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
} from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import DescriptionIcon from "@mui/icons-material/Description";
import { getHrData } from "../../redux/actions/hr";
import SpeedIcon from "@mui/icons-material/Speed";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import GroupsIcon from "@mui/icons-material/Groups";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import Button from "@mui/material/Button";
import { camalize, formatter} from "../../utils";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";


export const HRDashboard = ({ getHrData, hrData }) => {
  const [jobs, setJobs] = useState([]);
  const [applicants, setApplicants] = useState([]);
  const [totalApplicants, setTotalApplicants] = useState(0);
  const [totalJobs, setTotalJobs] = useState(0);
  const [totalEmployees, setTotalEmployees] = useState(0);
  const [empList, setEmpList] = useState([]);
  const [totalRecruits, setTotalRecruits] = useState(0);

  

  useEffect(() => {
    getHrData();
  }, []);

  useEffect(() => {
    if (hrData && hrData.jobs && hrData.jobs.count && hrData.jobs.count > 0) {
      setJobs(hrData.jobs.rows);
      setTotalJobs(hrData.jobs.count);
    }
    if (
      hrData &&
      hrData.apcts &&
      hrData.apcts.rows &&
      hrData.apcts.rows.length > 0
    ) {
      setApplicants(hrData.apcts.rows);
      setTotalApplicants(hrData.apcts.count);
    }
    if (hrData && hrData.employees && hrData.employees.rows.length > 0) {
      setEmpList(hrData.employees.rows);
      setTotalEmployees(hrData.employees.count);
    }
    if (hrData && hrData.totalRecruit) {
      setTotalRecruits(hrData.totalRecruit);
    }
  }, [hrData]);
  return (
    <>
      <Stack direction={{ xs: "row", sm: "row" }}>
        <SpeedIcon
          sx={{
            width: "50px",
            height: "50px",
          }}
        />{" "}
        <Typography
          sx={{ fontSize: 33, ml: 1, fontWeight: "bold" }}
          textAlign="center"
          variant="h4"
        >
          Dashboard
        </Typography>
      </Stack>
      {/* <Prompt when={true} message="Are you sure you want to leave this page?" /> */}
      <Stack
        direction={{ xs: "row", sm: "row" }}
        // sx={{ mt: 2 }}
        justifyContent="space-between"
      >
        <Card
          sx={{
            width: "24%",
            backgroundColor: (theme) => theme.palette.colors.light.c1,
          }}
        >
          <CardContent>
            <Stack
              direction={{ xs: "row", sm: "row" }}
              justifyContent="space-between"
            >
              <Stack direction={{ xs: "column", sm: "column" }}>
                <Typography
                  textAlign="center"
                  variant="h4"
                  color={(theme) => theme.palette.colors.dark.c1}
                >
                  {formatter.format(totalJobs)}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: "bold",
                    color: (theme) => theme.palette.colors.dark.c1,
                  }}
                  textAlign="center"
                  variant="h4"
                >
                  Job Description
                </Typography>
              </Stack>
              <DescriptionIcon
                sx={{
                  textAlign: "end",
                  width: "80px",
                  height: "80px",
                  color: (theme) => theme.palette.colors.dark.c1,
                }}
              />
            </Stack>
          </CardContent>
        </Card>

        <Card
          sx={{
            width: "24%",
            backgroundColor: (theme) => theme.palette.colors.light.c3,
          }}
        >
          <CardContent>
            <Stack
              direction={{ xs: "row", sm: "row" }}
              justifyContent="space-between"
            >
              <Stack direction={{ xs: "column", sm: "column" }}>
                <Typography
                  textAlign="center"
                  variant="h4"
                  color={(theme) => theme.palette.colors.dark.c3}
                >
                  {formatter.format(totalApplicants)}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: "bold",
                    color: (theme) => theme.palette.colors.dark.c3,
                  }}
                  textAlign="center"
                  variant="h4"
                >
                  Curriculum Vitae
                </Typography>
              </Stack>
              <GroupsIcon
                sx={{
                  textAlign: "end",
                  width: "80px",
                  height: "80px",
                  color: (theme) => theme.palette.colors.dark.c3,
                }}
              />
            </Stack>
          </CardContent>
        </Card>
        <Card
          sx={{
            width: "24%",
            backgroundColor: (theme) => theme.palette.colors.light.c1,
          }}
        >
          <CardContent>
            <Stack
              direction={{ xs: "row", sm: "row" }}
              justifyContent="space-between"
            >
              <Stack direction={{ xs: "column", sm: "column" }}>
                <Typography
                  textAlign="center"
                  variant="h4"
                  color={(theme) => theme.palette.colors.dark.c4}
                >
                  {formatter.format(totalRecruits)}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: "bold",
                    color: (theme) => theme.palette.colors.dark.c4,
                  }}
                  textAlign="center"
                  variant="h4"
                >
                  Recruitment
                </Typography>
              </Stack>
              <AssignmentIndIcon
                sx={{
                  textAlign: "end",
                  width: "80px",
                  height: "80px",
                  color: (theme) => theme.palette.colors.dark.c4,
                }}
              />
            </Stack>
          </CardContent>
        </Card>
        <Card
          sx={{
            width: "24%",
            backgroundColor: (theme) => theme.palette.grey[400],
          }}
        >
          <CardContent>
            <Stack
              direction={{ xs: "row", sm: "row" }}
              justifyContent="space-between"
            >
              <Stack direction={{ xs: "column", sm: "column" }}>
                <Typography
                  textAlign="center"
                  variant="h4"
                  color={(theme) => theme.palette.colors.dark.c2}
                >
                  {formatter.format(totalEmployees)}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: "bold",
                    color: (theme) => theme.palette.colors.dark.c2,
                  }}
                  textAlign="center"
                  variant="h4"
                >
                  Employees
                </Typography>
              </Stack>
              <PeopleAltIcon
                sx={{
                  textAlign: "end",
                  width: "80px",
                  height: "80px",
                  color: (theme) => theme.palette.colors.dark.c2,
                }}
              />
            </Stack>
          </CardContent>
        </Card>
      </Stack>
      <Stack
        direction={{ xs: "row", sm: "row" }}
        sx={{
          justifyContent: "space-between",
        }}
      >
        <Card
          raised
          sx={{
            width: "49%",
          }}
        >
          <CardContent>
            <Stack
              direction={{ xs: "row", sm: "row" }}
              sx={{
                justifyContent: "space-between",
                alignItems: "flex-end",
                marginBottom: 1,
              }}
            >
              <Stack
                direction={{ xs: "row", sm: "row" }}
                sx={{ alignItems: "flex-start" }}
              >
                <DescriptionIcon />

                <Typography
                  sx={{ fontSize: 16, ml: 1 }}
                  gutterBottom
                  textAlign="center"
                  variant="h4"
                >
                  Recent Job Descriptions
                </Typography>
              </Stack>
              <Stack direction={{ xs: "row", sm: "row" }}>
                {jobs && jobs.length == 0 ? (
                  <>
                    <Button
                      component={Link}
                      to="/hr/jd-list/create-jd"
                      variant="text"
                      color="primary"
                      size={"small"}
                    >
                      <AddIcon /> Create
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      component={Link}
                      to="/hr/jd-list/create-jd"
                      variant="text"
                      color="primary"
                      size={"small"}
                    >
                      <AddIcon /> Create
                    </Button>
                    <Button
                      component={Link}
                      to="/hr/jd-list"
                      variant="text"
                      color="primary"
                      size={"small"}
                    >
                      <VisibilityIcon /> View All
                    </Button>
                  </>
                )}
              </Stack>
            </Stack>
            <Divider />
            {jobs && jobs.length > 0 ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography sx={{ fontSize: 12 }}>Title</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: 12 }}>Batches</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: 12 }}>CVs</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {jobs.map((item) => (
                    <TableRow key={item.jb_id}>
                      <TableCell>
                        <Link to={`/hr/jd-detail/${item.jb_id}`}>
                          {item.jb_name}
                        </Link>
                      </TableCell>
                      <TableCell>
                        {item.batches}
                        {/* <Link to={`/hr/batches?jobId=${item.jb_id}`}>
                          {item.batches}
                        </Link> */}
                      </TableCell>
                      <TableCell>
                        {item.applicants}
                        {/* <Link to={`/hr/candidates?jobId=${item.jb_id}`}>
                          {item.applicants}
                        </Link> */}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              ""
            )}
          </CardContent>
        </Card>
        <Card
          raised
          sx={{
            width: "49%",
          }}
        >
          <CardContent>
            <Stack
              direction={{ xs: "row", sm: "row" }}
              sx={{
                justifyContent: "space-between",
                alignItems: "flex-end",
                marginBottom: 1,
              }}
            >
              <Stack
                direction={{ xs: "row", sm: "row" }}
                sx={{ alignItems: "flex-start" }}
              >
                <ManageHistoryIcon />
                <Typography
                  sx={{ fontSize: 16, ml: 1 }}
                  gutterBottom
                  textAlign="center"
                  variant="h4"
                >
                  Interview Tracking
                </Typography>
              </Stack>
              <Typography
                sx={{ fontSize: 12 }}
                gutterBottom
                textAlign="right"
                variant="h4"
              >
                <Button
                  component={Link}
                  to="/hr/interviews"
                  variant="text"
                  color="primary"
                  size={"small"}
                >
                  <VisibilityIcon /> View All
                </Button>
              </Typography>
            </Stack>
            <Divider />
            {jobs && jobs.length > 0 ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography sx={{ fontSize: 12 }}>
                        Job Description
                      </Typography>
                    </TableCell>
                    {/* <TableCell>
                      <Typography sx={{ fontSize: 12 }}>Batches</Typography>
                    </TableCell> */}
                    <TableCell>
                      <Typography sx={{ fontSize: 12 }}>Invite Sent</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: 12 }}>Completed</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {jobs.map((item) => (
                    <TableRow key={item.jb_id}>
                      <TableCell>
                        {" "}
                        <Link to={`/hr/jd-detail/${item.jb_id}`}>
                          {item.jb_name}
                        </Link>
                      </TableCell>
                      {/* <TableCell>
                        <Link to={`/hr/batches?jobId=${item.jb_id}`}>
                          {item.batches}
                        </Link>
                      </TableCell> */}
                      <TableCell>{item.applicantsInvited}</TableCell>
                      <TableCell>{item.applicantsCompleted}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              ""
            )}
          </CardContent>
        </Card>
      </Stack>

      <Stack
        direction={{ xs: "row", sm: "row" }}
        sx={{
          // marginTop: 3,
          justifyContent: "space-between",
        }}
      >
        <Card
          raised
          sx={{
            width: "49%",
          }}
        >
          <CardContent>
            <Stack
              direction={{ xs: "row", sm: "row" }}
              sx={{
                justifyContent: "space-between",
                alignItems: "flex-end",
                marginBottom: 1,
              }}
            >
              <Stack
                direction={{ xs: "row", sm: "row" }}
                sx={{ alignItems: "flex-start" }}
              >
                <GroupsIcon />

                <Typography
                  sx={{ fontSize: 16, ml: 1 }}
                  gutterBottom
                  textAlign="center"
                  variant="h4"
                >
                  {" "}
                  Candidate Status
                </Typography>
              </Stack>
              <Typography
                sx={{ fontSize: 12 }}
                gutterBottom
                textAlign="right"
                variant="h4"
              >
                <Button
                  component={Link}
                  to="/hr/candidates"
                  variant="text"
                  color="primary"
                  size={"small"}
                >
                  <VisibilityIcon /> View All
                </Button>
              </Typography>
            </Stack>
            <Divider />
            {applicants && applicants.length > 0 ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography sx={{ fontSize: 12 }}>Name</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: 12 }}>
                        Match Rate (%)
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: 12 }}>Status</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {applicants.map((item) => (
                    <TableRow key={item.apct_id}>
                      <TableCell>
                        {" "}
                        <Link
                          to={`/hr/candidates/candidate-profile/${item.apct_id}`}
                        >
                          {item.apct_name}
                        </Link>
                      </TableCell>
                      <TableCell>{item.apct_match_rate}</TableCell>
                      <TableCell>{camalize(item.apct_stage)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              ""
            )}
          </CardContent>
        </Card>
        <Card
          raised
          sx={{
            width: "49%",
          }}
        >
          <CardContent>
            <Stack
              direction={{ xs: "row", sm: "row" }}
              sx={{
                justifyContent: "space-between",
                alignItems: "flex-end",
                marginBottom: 1,
              }}
            >
              <Stack
                direction={{ xs: "row", sm: "row" }}
                sx={{ alignItems: "flex-start" }}
              >
                <PeopleAltIcon />
                <Typography
                  sx={{ fontSize: 16, ml: 1 }}
                  gutterBottom
                  textAlign="center"
                  variant="h4"
                >
                  Employee Status
                </Typography>
              </Stack>
              <Typography
                sx={{ fontSize: 12 }}
                gutterBottom
                textAlign="right"
                variant="h4"
              >
                <Button
                  component={Link}
                  to="/hr/employees"
                  variant="text"
                  color="primary"
                  size={"small"}
                >
                  <VisibilityIcon /> View All
                </Button>
              </Typography>
            </Stack>
            <Divider />
            {empList && empList.length > 0 ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography sx={{ fontSize: 12 }}>Name</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: 12 }}>Total Exp</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: 12 }}>Rel. Exp</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {empList.map((item) => (
                    <TableRow key={item.u_id}>
                      <TableCell> {item.u_name}</TableCell>
                      <TableCell>{item.se_overall_experience}</TableCell>
                      <TableCell>{item.se_relevant_experience}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              ""
            )}
          </CardContent>
        </Card>
      </Stack>
    </>
  );
};

const mapStateToProps = (state) => ({
  hrData: state.hr.hrData,
});

const mapDispatchToProps = { getHrData };

export default connect(mapStateToProps, mapDispatchToProps)(HRDashboard);
